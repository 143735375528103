<template>
  <div>
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="true"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Notifications Logs") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    v-model="search"
                    :title="$t('Search')"
                  />
                </label>
                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                        @click="InitializeDatePicker"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.status"
                          :label="$i18n.t('Status')"
                          :items="status"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field
                          v-model="filter.dateFrom"
                          solo
                          :label="$i18n.t('Date From')"
                          id="date_from"
                        ></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field
                          v-model="filter.dateTo"
                          solo
                          :label="$i18n.t('Date To')"
                          id="date_to"
                        ></v-text-field>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
                <!-- bulk delete -->
                <v-icon
                  class="main-color"
                  large
                  @click="bulkDelete"
                  :title="$t('Bulk Delete')"
                  >delete</v-icon
                >
                <v-icon
                  class="main-color"
                  large
                  @click="bulkResend"
                  :title="$t('Resend')"
                  >undo</v-icon
                >
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->

                <v-btn
                  class="modal-btn-save saveBtn"
                  @click="clearLog"
                  v-can="'clear-sms-log'"
                  >{{ $t("Clear Log") }}</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.to="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.to.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.to }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.message="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.message.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.message }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.error_msg="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.error_msg.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.error_msg }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              @click="showMessage(item.message, item.to)"
              :title="$t('Show Message')"
              >visibility</v-icon
            >
            <v-icon @click="resendMessage(item.id)" :title="$t('Resend')"
              >undo</v-icon
            >
            <v-icon @click="deleteMessage(item.id)" :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialogShow"
      max-width="500"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{ recepient }}</v-card-title>

        <v-card-text v-html="message"></v-card-text>
        <v-card-actions>
          <!-- <v-btn class="modal-btn-cancel" @click="dialogShow = false">
            Close
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
// import ACL from "../acl";
export default {
  name: "notificationsLogs",
  data() {
    return {
      status: [
        { name: this.$i18n.t("Success"), id: 1 },
        { name: this.$i18n.t("Failed"), id: 0 }
      ],
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Recipents"),
          value: "to",
        },
        {
          text: this.$i18n.t("Message"),
          value: "message",
        },
        {
          text: this.$i18n.t("Status"),
          value: "status",
        },
        {
          text: this.$i18n.t("Reason"),
          value: "error_msg",
        },
        {
          text: this.$i18n.t("Date"),
          value: "date",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      filter: {
        dateFrom: "",
        dateTo: "",
        status: [],
      },
      search: "",
      /**pagination */
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      message: "",
      recepient: "",
      dialogShow: false,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.$router.push(
        {
          path: "/notificationsLogs?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    showMessage(msg, to) {
      this.message = msg;
      this.recepient = to;
      this.dialogShow = true;
    },
    InitializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#date_from").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.dateFrom = dateH;
            } else {
              _this.filter.dateFrom = "";
            }
          },
        });

        $("#date_to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.dateTo = dateH;
            } else {
              _this.filter.dateTo = "";
            }
          },
        });
      }, 500);
    },
    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(this.getApiUrl + "/logs?search=" + this.search + "&page=" + page, {
          params: {
            filter: this.filter,
          },
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    resendMessage(id) {
      axios
        .get(this.getApiUrl + "/logs/resendMessage/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.getAllData();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        });
    },
    deleteMessage(id) {
      axios
        .get(this.getApiUrl + "/logs/destroy/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.getAllData();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        });
    },
    bulkResend() {
      if (this.selected.length == 0) {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = this.$i18n.t("Please choose messages first");
      } else {
        let ids = [];
        this.selected.forEach((item) => {
          ids.push(item.id);
        });
        axios
          .post(
            this.getApiUrl + "/logs/bulkResendMessage",
            { ids: ids },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              this.getAllData();
            } else {
              this.snack = true;
              this.snackColor = "red";
              this.snackText = response.data.status.message;
            }
          });
      }
    },
    bulkDelete() {
      if (this.selected.length == 0) {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = this.$i18n.t("Please choose messages first");
      } else {
        let ids = [];
        this.selected.forEach((item) => {
          ids.push(item.id);
        });
        axios
          .post(
            this.getApiUrl + "/logs/bulkDelete",
            { ids: ids },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.snack = true;
              this.snackColor = "green";
              this.snackText = response.data.status.message;
              this.getAllData();
            } else {
              this.snack = true;
              this.snackColor = "red";
              this.snackText = response.data.status.message;
            }
          });
      }
    },
    clearLog() {
      axios
        .get(this.getApiUrl + "/logs/ClearLogByForceDelete", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            this.getAllData();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
#wrapper.active {
  // padding-left: 200px;
}

/* start new interface */
.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

img {
  height: 20px !important;
  margin: 3px !important;
}
img.category {
  height: 60px !important;
  border-radius: 50%;
  margin: 0 !important;
}
.select {
  margin-top: 20px !important;
}
.status {
  text-transform: capitalize;
}

.buttons-space {
  margin-bottom: 40px;
  margin-top: 30px;
}
/* end new interface */

.upgradeIcon {
  cursor: pointer;
  font-size: 1rem;
  color: #6d6d6d;
}
.bulkUpgradeIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: $main-color;
}
.exams-table .cart {
  padding-bottom: 0 !important;
}

.custom-bg {
  color: red;
  text-decoration: line-through;
}

.redRow {
  color: red;
}

.circle {
  width: 65px;
  height: 65px;
  border: 3px solid #7297ff;
  background-color: #f1f1f1;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}
</style>
